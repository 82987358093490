import { useCallback, useState } from 'react';

interface HookValue {
  state: boolean;
  toggle: () => void;
  setTrue: () => void;
  setFalse: () => void;
  setState: (value: boolean) => void;
}

export const useBoolState = (defaultValue = false): HookValue => {
  const [state, setState] = useState(defaultValue);

  const setTrue = useCallback(() => setState(true), []);
  const setFalse = useCallback(() => setState(false), []);
  const toggle = useCallback(() => setState((prevState) => !prevState), []);

  return { state, setState, setTrue, setFalse, toggle };
};

export default useBoolState;
